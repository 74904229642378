export default {
  methods: {
    filterAndMergeEasyPostCarriers(carriers) {
      const mergedCarriers = {};
      carriers.forEach((item) => {
        // Se saca el quoteContractId o el ID, porque los carriers de storerates comparten el mismo quoteContractId
        // eslint-disable-next-line no-underscore-dangle
        const key = item.source.toLowerCase() === "easy-post" ? item.quoteContractId : item._id;
        const hasParcelPickUpFee = item?.accesorials?.some(
          (accessorial) => accessorial?.Name?.toLowerCase() === "parcel pick up fee"
        );
        if (!mergedCarriers[key] && !hasParcelPickUpFee) {
          mergedCarriers[key] = item;
        }
      });
      return Object.values(mergedCarriers);
    },
  },
};
